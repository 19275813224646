<template>
  <section class="px-10">
    <v-breadcrumbs class="px-0" :items="breadcrumbs" divider="/" />
    <p class="text-h4 secondary--text"> {{ titulo }}</p>
    <data-table-component
        :headers="headers"
        :items="listaEspecificacionObs.subProceso"
        :total_registros="params.filtros.total_rows"
        v-models:pagina="params.filtros.page"
        v-models:select="params.filtros.per_page"
        @paginar="paginate"
        class="mb-16"
    >
        <template #[`item.acciones`]="{ item }">
            <v-btn 
              out color="secondary" 
              v-if="!item.ProveedorParticipacionObs.length"
              @click="ofertar(item)"
            >Ofertar</v-btn>
            <v-btn 
              outlined 
              color="secondary" 
              v-else 
              @click="ofertar(item)"
            >Ver oferta</v-btn>
        </template>
    </data-table-component>
    <v-btn class="mt-16" color="secondary" outlined :to="`/proceso-compra-paac/${params.procesoCompraId}`">volver</v-btn>
  </section>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent";
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
    name: 'VerObsPaac',
    components: {
        DataTableComponent
    },
  data() {
    return {
      titulo: null,
      breadcrumbs: [
        {
          text: "Procesos",
          disabled: false,
          href: "#",
        },
        {
          text: "0200-2022-P1231",
          disabled: false,
          href: "#",
        },
        {
          text: "Ofertas",
          disabled: false,
          href: "#",
        },
      ],

      params: { 
        procesoCompraId: this.$route.params.id_proceso_compra,
        procesoObsId: this.$route.params.id_obs,
        filtros: {
          page: 1,
          per_page: 10,
          total_rows: null
        },
      },

      headers: [
        { text: 'Especificación', width: '70%', value: 'especificacion' },
        { text: 'Cantidad', value: 'total_sub_proceso' },
        { text: 'Acciones', value: 'acciones', sortable: false}
      ],

      datosTabla: null,
    };
  },

  watch: {
    listaEspecificacionObs () {
      if( this.listaEspecificacionObs?.subProceso.length === 0 ) return
      this.titulo = 
        `${this.listaEspecificacionObs?.subProceso[0]?.proceso_obs?.obs?.codigo} ${this.listaEspecificacionObs?.subProceso[0]?.proceso_obs?.obs?.nombre}`
    }
  },

  computed : {
    ...mapState('etapaRecepcionOfertas', ['listaEspecificacionObs']),
  },

  methods: {
    ...mapActions('etapaRecepcionOfertas', ['getEspecificacionObs']),

    ...mapMutations('etapaRecepcionOfertas', ['setTieneOferta']),

    async obtenerSubprocesos () {
      let response = await this.getEspecificacionObs(this.params)
      this.setPaginacion(response.headers)
    },

    setPaginacion (payload) {
      this.params.filtros.page = Number(payload.page)
      this.params.filtros.per_page = Number(payload.per_page)
      this.params.filtros.total_rows = Number(payload.total_rows)
    },

    async paginate (params) {
      const { cantidad_por_pagina, pagina} = params;
      this.params.filtros.page = pagina,
      this.params.filtros.per_page = cantidad_por_pagina,
      await this.obtenerSubprocesos();
    },

    ofertar (item) {
      this.setTieneOferta(item.ProveedorParticipacionObs.length ? true : false)
      this.$router.push({ name: 'ofertar-paac', params: { id_subproceso:  item.id} })
    }
  },

  async created() {
    await this.obtenerSubprocesos()
      let codigoNombreObs = { 
        disabled: false,
        href: '#',
        text: 
        `${ this.listaEspecificacionObs?.subProceso[0]?.proceso_obs?.obs?.codigo } ${this.listaEspecificacionObs?.subProceso[0]?.proceso_obs?.obs?.nombre}`,
      }
      this.breadcrumbs.push(codigoNombreObs)
  }
};
</script>

<style>
</style>