<template>
  <v-row class="px-10">
    <v-col cols="12">
      <v-breadcrumbs class="px-0" :items="breadcrumbs" divider="/" />
      <app-countdown :dateNow="moment()" :nextDay="moment().add(10, 'hours')" />
    </v-col>
    <v-col cols="12" sm="12" lg="6">
      <p class="text-h4 text-center secondary--text">
        {{ datos?.nombre_agrupacion }}
      </p>
      <v-data-table
        :headers="headers"
        :items="obsOfertar"
        hide-default-footer
        no-data-text="No hay datos"
      >
        <template #[`item.monto_obs`]="{ item }">
          $ {{ 
            Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(item.monto_obs)
          }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" sm="12" lg="6">
      <v-card width="500">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                outlined
                prepend-icon=""
                :v-model="!fileOferta ?? ''"
                :error-messages="errorDocument"
                placeholder="Adjuntar archivo*"
                label="Archivo"
                accept="application/pdf"
                prepend-inner-icon="mdi-paperclip"
                @blur="$v.fileOferta.$touch()"
              >
                <template
                  v-if="tieneOferta && fileOferta !== null"
                  v-slot:append
                >
                  <v-icon @click="descargarDocumento" color="green"
                    >mdi-file-download</v-icon
                  >
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6" md="6">
              <money-text-field-component
                ref="precioUnitarioRef"
                v-model="precioUnitario"
                label="Precio unitario*"
                :error-messages="errorMessages"
                @blur="$v.precioUnitario.$touch()"
                @input="$v.precioUnitario.$touch()"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                outlined
                v-model.number="cantidad"
                label="Cantidad"
                readonly
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                outlined
                v-model="totalOferta"
                label="Total oferta"
                prefix="$"
                :error-messages="errorMessages"
                @blur="$v.totalOferta.$touch()"
                @input="$v.totalOferta.$touch()"
                @change="formartNumber()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="secondary" @click="ofertar()">Ofertar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="d-flex justity-start mt-16">
      <v-btn class="mt-16" color="secondary" outlined 
        @click="$router.push({ name: 'proceso-compra-paac', params: { id_proceso_compra: params.id_proceso_compra } })"
      >volver</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import chat from "@/components/ChatComponent.vue";
import DataTable from "@/components/DataTableComponent.vue";
import PaacServices from "@/services/Paac.services";
import { required, helpers } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent";
import LegacyValidations from "@/utils/legacy-validations";
import { mapActions, mapMutations, mapState } from "vuex";

const currency = helpers.regex(
  "currency",
  /^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$/
);

function fileNameValid(value) {
  if (!value?.name) {
    return true;
  } else {
    let extension = /(\.pdf)$/i;
    let file = value;
    return extension.test(file.name);
  }
}

export default {
  name: "ofertarPaac",
  components: {
    chat,
    DataTable,
    MoneyTextFieldComponent,
  },
  data() {
    return {
      params: {
        id_proceso_compra: this.$route.params.id_proceso_compra,
        id_proceso_obs: this.$route.params.id_obs,
        id_sub_proceso_obs: this.$route.params.id_subproceso,
      },
      params2: {
        id_proceso_compra: this.$route.params.id_proceso_compra,
        id_proceso_obs: this.$route.params.id_obs,
        id_sub_proceso_obs: this.$route.params.id_subproceso,
        id_proveedor_participacion: this.$route.params.id_proveedor_participante
      },
      total_oferta:null,
      datos: null,
      docName: null,
      docPath: null,
      breadcrumbs: [
        {
          text: "Procesos",
          disabled: false,
          href: "#",
        },
        {
          text: "0200-2022-P1231",
          disabled: false,
          href: "#",
        },
        {
          text: "Ofertas",
          disabled: false,
          href: "#",
        },
      ],
      headers:[
        {
          text:"Obs",
          value:"nombre"
        },
        {
          text:"Total del obs",
          align:"center",
          value:"total_obs"
        }
      ]
    };
  },

  validations: {
    totalOferta: { required, currency },
    fileOferta: { 
      fileNameValid, 
      fileSizeValid: LegacyValidations.maxFileSizeRule(5000000),
    },
  },

  computed: {
    ...mapState("etapaRecepcionOfertas", [
      "detalleSubprocesoObs",
      "formOfertar",
      "tieneOferta",
      "obsOfertar"
    ]),

    totalOferta: {
      get(){
        return this.total_oferta;
      },
      set(value){
        this.total_oferta = value
      }
    },
    fileOferta: {
      get() {
        return this.formOfertar.oferta;
      },
      set(value) {
        this.formOfertar.oferta = value;
      },
    },
    precioUnitario: {
      get() {
        return this.formOfertar.precio_unitario;
      },
      set(value) {
        this.formOfertar.precio_unitario = value;
      },
    },
    cantidad: {
      get() {
        return this.formOfertar.cantidad;
      },
      set(value) {
        this.formOfertar.cantidad = value;
      },
    },

    errorMessages() {
      const errors = [];
      if (!this.$v.totalOferta.$dirty) return errors;
      !this.$v.totalOferta.required &&
        errors.push("Total de oferta es obligatorio");
      !this.$v.totalOferta.currency &&
        errors.push("Total de oferta es invalido");
      return errors;
    },

    errorDocument() {
      const errors = [];
      if (!this.$v.fileOferta.$dirty) return errors;
      !this.$v.fileOferta.fileNameValid &&
        errors.push("El archivo debe ser de tipo PDF.");
      !this.$v.fileOferta.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },

  methods: {
    ...mapActions("etapaRecepcionOfertas", [
      "getDetalleSubProcesoPaac",
      "getDetalleSubProcesoPaacOferta",
    ]),
    ...mapMutations("etapaRecepcionOfertas", ["setTieneOferta"]),

    async documentoBases() {
      if (this.docPath === 'undefined') return;

      const response = await PaacServices.downloadDocumentoProcesoCompra(
        this.docPath
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
        return;
      }
      const blob = new Blob([response.data], { type: "application/pdf" });
      this.fileOferta = new File([blob], `${this.docName}`, {
        type: "application/pdf",
      });
    },

    async descargarDocumento() {
      const response = await PaacServices.downloadDocumentoProcesoCompra(
        this.docPath
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
        return;
      }

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileUrl;
      fileLink.setAttribute("download", `${this.docName}`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    async obtenerDetalleSubprocesoOferta() {
      let response;
      let res;
      if (this.tieneOferta) {
        response = await this.getDetalleSubProcesoPaacOferta(this.params);
        this.docName = response?.ofertas[0]?.nombre_documento;
        this.docPath = response?.ofertas[0]?.url;
        this.total_oferta = response?.ofertas[0].total
        await this.documentoBases();
        return;
      }
      response = await this.getDetalleSubProcesoPaac(this.params);
    },

    async ofertar() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
        const { id_proceso_compra, id_proceso_obs, id_sub_proceso_obs, id_proveedor_participacion } =
        this.params;
      const { oferta, precio_unitario, cantidad} =
        this.formOfertar;
      let response;
      let msg;
      let formData = new FormData();

      // const precioUnit = Number(precio_unitario.toString().replaceAll(",", ""));

      formData.append("oferta", oferta);
      // formData.append("precio_unitario", precioUnit);
      // formData.append("cantidad", cantidad);
      formData.append("total_oferta", this.total_oferta);

        if (this.tieneOferta) {
          response = await this.services.Proveedores.putOfertaPaac(
            this.params2.id_proceso_compra,
            this.params2.id_sub_proceso_obs,
            this.params2.id_proveedor_participacion,
            this.detalleSubprocesoObs.ofertas[0].id,
            formData
          );

          msg = "Se actualizó la oferta correctamente";
        } else {
          response = await this.services.Proveedores.postOfertaPaac(
            id_proceso_compra,
            id_proceso_obs,
            id_sub_proceso_obs,
            formData
          );

          msg = "Se agregó la oferta correctamente";
        }

        if (response?.status === 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: msg,
          });

          let params = this.params;

          delete params.id_sub_proceso_obs;

          this.$router.push({ name: 'proceso-compra-paac', params: { id_proceso_compra: params.id_proceso_compra } });
      }
    },

    clearForm() {
      this.cantidad = null;
      this.fileOferta = {};
      this.precioUnitario = null;
      this.formOfertar.total_oferta = null;

      // this.$refs.precioUnitarioRef.clear();
    },
    formartNumber() {
      if (!/^(\d{1,3}(\,\d{3})*|(\d+))(\.\d{1,2})?$/.test(this.total_oferta)) this.total_oferta = 0;

      this.totalOferta = Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.total_oferta);
    }
  },

  watch: {
    detalleSubprocesoObs() {
      if (this.tieneOferta) {
        this.datos = this.detalleSubprocesoObs.ofertas[0].SubProcesoOb;
        return;
      }

      this.datos = this.detalleSubprocesoObs;
    },
    // precioUnitario() {
    //   if(this.tieneOferta){
    //     this.$refs.precioUnitarioRef.updateValue(this.formOfertar.precio_unitario)
    //   }
    //   if (this.cantidad && this.precioUnitario) {
    //     this.formOfertar.total_oferta = this.cantidad * this.precioUnitario;
    //   } else {
    //     this.formOfertar.total_oferta = null;
    //   }
    // },
  },

  created() {
    if (this.tieneOferta === null) {
      this.$router.go(-1);
      return;
    }
    this.obtenerDetalleSubprocesoOferta();
    if (this.total_oferta !== '') this.formartNumber();
  },

  destroyed() {
    this.setTieneOferta(null);
    this.clearForm();
  },
};
</script>
